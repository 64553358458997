import { render, staticRenderFns } from "./AppSapphire.vue?vue&type=template&id=2ba791ef&scoped=true"
import script from "./AppSapphire.vue?vue&type=script&lang=js"
export * from "./AppSapphire.vue?vue&type=script&lang=js"
import style0 from "./AppSapphire.vue?vue&type=style&index=0&id=2ba791ef&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba791ef",
  null
  
)

export default component.exports